<template>
  <div v-if="mode == 'tablet'" class="fw-multiplechoice flex flex-col gap-2">
    <div
      v-for="(option, o) in options_tmp"
      :key="$vnode.key + '_multiple_choice_option_' + o"
      class="multiplechoice_option -mt-2 relative focus:border-b rounded focus:border-gray-200 active:border-b active:border-gray-200 border-b border-gray-200 py-3 pl-12 pr-24 font-medium block w-full text-left"
      :class="{
        selected: option.selected && !saving,
        saving: saving,
        disabled: !canOptionBeSelected(option),
        inactive: isOptionDisabled(option)
      }"
    >
      <div
        class="multiplechoice_letter absolute text-center py-2 bg-white uppercase rounded-xl leading-4 text-lg font-medium left-0.5"
        style="width: 2rem"
      >
        <div>{{ String.fromCharCode(97 + o) }}</div>
      </div>
      <div v-if="language && typeof option.text !== 'string'">{{ option.text[language] }}</div>
      <div v-else>{{ option.text }}</div>
      <div v-if="option.selected && !disabled" class="action_label deselect_label" @click="selectOption(o)">
        Retirar
      </div>
      <div
        v-else-if="canOptionBeSelected(option) && !maxSelectedOptionsReached && !disabled"
        class="action_label select_label"
        @click="selectOption(o)"
      >
        Escolher
      </div>
    </div>
  </div>
  <div v-else class="fw-multiplechoice flex flex-col gap-2">
    <button
      v-for="(option, o) in options_tmp"
      :key="$vnode.key + '_multiple_choice_option_' + o"
      class="multiplechoice_option -mt-2 relative focus:border-b rounded focus:border-gray-200 active:border-b active:border-gray-200 border-b border-gray-200 py-2.5 pl-12 pr-24 font-medium block w-full text-left"
      :class="{
        selected: option.selected && !saving,
        saving: option.selected && saving,
        disabled: !canOptionBeSelected(option),
        inactive: isOptionDisabled(option)
      }"
      @click="selectOption(o)"
    >
      <div
        class="multiplechoice_letter absolute text-center py-2 bg-white uppercase rounded-xl leading-4 text-lg font-medium left-0.5"
        style="width: 2rem"
      >
        <div>{{ String.fromCharCode(97 + o) }}</div>
      </div>
      <div v-if="language && typeof option.text !== 'string'">{{ option.text[language] }}</div>
      <div v-else>{{ option.text }}</div>
      <div v-if="option.selected" class="action_label deselect_label">Retirar</div>
      <div v-else-if="canOptionBeSelected(option) && !maxSelectedOptionsReached" class="action_label select_label">
        Escolher
      </div>
    </button>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'MultipleChoice',
  props: {
    selected: {
      type: Array,
      default: function() {
        return []
      }
    },
    max: {
      type: Number,
      default: 1
    },
    random: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: function() {
        return []
        /*
        {
              key: '',
              text: '', //can be a multilanguage string (with selected language: language prop)
            },
         */
      }
    },
    language: {
      type: String
    },
    saving: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'tablet'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      options_tmp: [],
      selected_order: []
      //loading: false,
    }
  },
  computed: {
    selectedOptions() {
      return this.options_tmp.filter(o => o.selected)
    },
    maxSelectedOptionsReached() {
      return this.selectedOptions.length >= this.max
    }
  },
  created() {
    this.options_tmp = this.options.map(option => {
      option.last = 0
      if (this.selected.includes(option.key)) {
        option.selected = true
      } else {
        option.selected = false
      }
      return option
    })
    if (this.random) {
      /* Randomize array in-place using Durstenfeld shuffle algorithm */
      let options = this.options_tmp
      for (var i = options.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1))
        var temp = options[i]
        options[i] = options[j]
        options[j] = temp
      }
      this.options_tmp = options
    }
  },
  methods: {
    serverData(data) {
      //this.loading = false
      console.log('SETTING MC DATA FROM SERVER', data)
    },
    selectOption(option) {
      if (this.disabled) return
      if (this.saving) return

      let currentState = this.options_tmp[option]

      // Avoid saving when max options are selected (user must remove one to allow a new option to be selected)
      if (!currentState.selected && this.max > 1 && this.maxSelectedOptionsReached) return

      // Invert selection
      currentState.selected = !currentState.selected

      // Allow only one option to be selected (remove the selected option)
      if (this.max === 1) {
        // Unselect others
        for (let i = 0; i < this.options_tmp.length; i++) {
          if (i !== option) {
            let currentState = this.options_tmp[i]
            currentState.selected = false
            Vue.set(this.options_tmp, i, currentState)
          }
        }
        //this.$emit('selected', [currentState.key])
      }
      // When dealing with more than one option allowed, do not remove any
      // User must remove one option to allow a new one to be selected
      // Or, keep track of the last one selected and remove it
      else {
        if (currentState.selected) {
          this.selected_order.push(option)
        } else {
          let index = this.selected_order.indexOf(option)
          if (index !== -1) {
            this.selected_order.splice(index, 1)
          }
        }
      }
      Vue.set(this.options_tmp, option, currentState)
      this.emitSelected()
    },
    emitSelected() {
      let selected = []
      for (let i = 0; i < this.options_tmp.length; i++) {
        if (this.options_tmp[i].selected) {
          selected.push(this.options_tmp[i].key)
        }
      }
      console.log('selected', selected)
      if (selected.length > this.max) {
        let unselect = selected.length - this.max
        for (let u = 0; u < unselect; u++) {
          let pos = this.selected_order[u]
          let currentState = this.options_tmp[pos]
          currentState.selected = false
          Vue.set(this.options_tmp, pos, currentState)
        }
        //Remove unselected from selected_order
        this.selected_order.splice(0, unselect)
        //reset selected
        selected = []
        //get selected options again
        for (let i = 0; i < this.options_tmp.length; i++) {
          if (this.options_tmp[i].selected) {
            selected.push(this.options_tmp[i].key)
          }
        }
      }

      this.$emit('selected', selected)
    },
    isOptionDisabled(option) {
      return !option.selected && this.selectedOptions.length >= this.max
    },
    canOptionBeSelected(option) {
      return this.max === 1 || (this.max > 1 && !this.isOptionDisabled(option))
    }
  }
}
</script>

<style>
.multiplechoice_option .multiplechoice_letter {
  top: 0.5rem;
  @apply text-gray-700 bg-gray-300;
}
.multiplechoice_option:hover .multiplechoice_letter {
}
.multiplechoice_option:not(.saving):hover .select_label {
  color: rgb(0, 0, 0);
  @apply border-black;
}
.multiplechoice_option .deselect_label {
  color: rgba(235, 66, 41, 1);
}
/*.multiplechoice_option:focus .multiplechoice_letter {
  color: rgba(3, 164, 121, 0.7);
}*/
.multiplechoice_option.saving .multiplechoice_letter {
  @apply opacity-90;
}
.multiplechoice_option.selected {
  color: #000000;
}

.multiplechoice_option.selected .multiplechoice_letter {
  background-color: #03b78e;
  box-shadow: 0px 0px 2px #03b78e;
  @apply text-white;
}
.multiplechoice_option.disabled {
  @apply opacity-50 cursor-not-allowed;
}
.multiplechoice_option.inactive {
  @apply opacity-50;
}
.multiplechoice_option.focus {
  @apply border-b border-gray-200;
}
.multiplechoice_option.selected {
  color: #03b78e;
}
.multiplechoice_option .action_label {
  top: 0.3rem;
  @apply absolute text-center right-2 w-24 text-sm font-semibold border border-gray-300 rounded-full py-2;
}

.multiplechoice_option.saving .action_label {
  @apply opacity-40;
}

.multiplechoice_option.selected .select_label {
  @apply text-black;
}
</style>
