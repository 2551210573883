<template>
  <div :id="id + '_form_section_' + (section[keyName] ? section[keyName] : '')" class="flex-col relative">
    <div
      v-if="
        (hasSection &&
          language &&
          typeOf(section.title) !== 'string' &&
          section.title[language] &&
          section.title[language].length > 0) ||
          section.title.length > 0
      "
      class="flex gap-3 mb-3 items-center px-5 md:px-0 nohighlight"
    >
      <div v-if="language && typeOf(section.title) !== 'string'" class="relative font-bold text-2xl">
        {{ section.title[language] }}
      </div>
      <div v-else-if="section.title" class="relative font-bold text-2xl">
        {{ section.title }}
      </div>
      <div
        v-if="section.required === true && editable"
        class="bg-gray-800 text-gray-100 rounded-full px-2 py-0.5 text-xs font-bold"
      >
        {{ mandatoryString }}
      </div>
      <div
        v-else-if="section.required === false && editable"
        class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold"
      >
        {{ optionalString }}
      </div>
      <div class="flex-1"></div>
    </div>
    <div class="flex flex-col bg-white border border-gray-100 rounded-lg p-5 gap-5">
      <div
        v-for="(piece, p) in section.content"
        :id="'question-' + id + '-' + piece.key"
        :key="'piece_' + p"
        class="relative"
      >
        <div v-if="piece.type != 'bool' && piece.type != 'switch'" class="flex items-center gap-2 mb-2 w-full">
          <div class="flex-1">
            <label
              v-if="language && typeOf(piece.label) !== 'string'"
              class="font-semibold text-gray-500 text-sm"
              :for="piece[keyName]"
              v-html="piece.label[language]"
            />
            <label
              v-else-if="piece.label"
              class="font-semibold text-gray-500 text-sm"
              :for="piece[keyName]"
              v-html="piece.label"
            />
          </div>
          <div
            v-if="piece.required === true"
            class="bg-gray-800 text-gray-100 rounded-full px-2 py-0.5 text-xs font-bold nohighlight"
          >
            {{ mandatoryString }}
          </div>
          <div
            v-else-if="piece.required === false"
            class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold nohighlight"
          >
            {{ optionalString }}
          </div>
        </div>
        <div
          v-if="piece.file != null && piece.file.type === 'image'"
          class="overflow-hidden rounded-2xl mb-2 nohighlight"
        >
          <img
            class="w-full cursor-pointer"
            loading="lazy"
            :src="getImageViewUrl(piece.file, 'max800')"
            @click="openImageFullscreen(piece.file, piece)"
          />
        </div>
        <div v-else-if="piece.file != null && piece.file.type === 'video'" class="nohighlight">
          <video-player
            :ref="'video-' + piece[keyName]"
            :src="getFileViewUrl(piece.file)"
            :type="piece.file.mimetype"
          />
        </div>
        <TextInput
          v-if="piece.type === 'text_input'"
          :id="piece[keyName]"
          :value="tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : ''"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
          :placeholder="piece.placeholder[language]"
          :disabled="!editable"
          @input="dataChanged(piece[keyName], piece.type, $event)"
          @focus="focusInput(piece[keyName])"
          @blur="focusInput(piece[keyName], null, 'blur')"
        >
          <template v-if="piece.prefix" #prefix>{{ piece.prefix }}</template>
          <template v-if="piece.suffix" #suffix>{{ piece.suffix }}</template>
        </TextInput>
        <HtmlEditor
          v-if="piece.type === 'text_html' || piece.type === 'formated_text'"
          allow-paste
          :disabled="!editable"
          :value="tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : ''"
          @input="tmp_data[piece[keyName]] = $event"
        />
        <TranslatedInput
          v-if="piece.type === 'text_area'"
          :maxlength="piece.maxChars"
          :maxchars="piece.maxChars"
          :value="tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : ''"
          :multiline="true"
          :autogrow="true"
          :min-height="200"
          :disabled="!editable"
          :placeholder="'Escreva aqui...'"
          :saving="saving && (dirty.has(piece.key) || failed.has(piece.key))"
          @input="dataChanged(piece[keyName], piece.type, $event)"
          @save="dataChanged(piece[keyName], piece.type, $event, true)"
          @focus="focusInput(piece[keyName])"
          @blur="focusInput(piece[keyName], null, 'blur')"
        ></TranslatedInput>
        <div v-else-if="piece.type === 'group'" class="grid gap-3 grid-cols-2 md:grid-cols-4 nohighlight">
          <div
            v-for="(subpiece, ss) in piece.content"
            :key="'subpiece_' + ss"
            :class="{
              //'w-32': subpiece.type === 'postal_code',
              //'flex-1': subpiece.type !== 'postal_code',
              'col-span-1 md:col-span-3': subpiece[keyName] === 'locality',
              error: errors && errors[subpiece[keyName]] && errors[subpiece[keyName]].length > 0 && editable
            }"
          >
            <label v-if="subpiece.label" class="font-semibold text-gray-500 mb-1 text-xs" :for="subpiece[keyName]">
              {{ subpiece.label }}
            </label>
            <TextInput
              v-if="subpiece.type === 'postal_code' || subpiece.type === 'text_input'"
              :value="tmp_data[subpiece[keyName]] ? tmp_data[subpiece[keyName]] : ''"
              :placeholder="subpiece.placeholder"
              :disabled="!editable"
              @input="dataChanged(subpiece[keyName], subpiece.type, $event)"
              @focus="focusInput(subpiece[keyName])"
              @blur="focusInput(subpiece[keyName], null, 'blur')"
            ></TextInput>
          </div>
        </div>
        <CountriesSelect
          v-else-if="piece.type === 'country_input'"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
          :disabled="!editable"
          :input="tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : ''"
          @update="dataChanged(piece[keyName], piece.type, $event)"
          @focus="focusInput(piece[keyName])"
          @blur="focusInput(piece[keyName], null, 'blur')"
        />
        <div
          v-else-if="piece.type === 'upload_file'"
          class="nohighlight"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
        >
          <p v-if="tmp_data[piece[keyName]].length === 0 && editable === false" class="instructions">
            {{ $t('noSectionDocuments') }}
          </p>
          <div class="files has-margin-top">
            <RecordFileEntry
              v-for="file in tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : []"
              :key="file.key"
              :can-edit="editable"
              :allow-classified="false"
              :file="file"
              :can-download="typeOf(file.url_format) == 'string' || typeOf(file.url == 'string')"
              @remove="removeFile(piece[keyName], $event)"
              @save="saveFile(piece[keyName], $event)"
              @download="downloadFile"
              @preview="previewFile"
            />
          </div>
          <StorageUploader
            v-if="
              editable &&
                (typeOf(piece.max) === 'undefined' || (piece.max && tmp_data[piece[keyName]].length < piece.max))
            "
            :label="'Carregar ficheiros'"
            :is-docked="true"
            :layout="'minimal'"
            :reference-id="'uploader_' + piece[keyName]"
            :clear-after="true"
            :upload-path="piece.uploadPath"
            input-id="upload_input"
            bucket-code="files"
            bucket-type="file"
            :files.sync="filesToUpload"
            :size="0"
            :new-file-context="newFileContext"
            :file-type="piece.fileType || 'file'"
            :file-code="piece.fileCode"
            class="cursor-pointer"
            @focus="focusInput(piece[keyName])"
            @blur="focusInput(piece[keyName], null, 'blur')"
            @upload="uploadedToStorage(piece, piece[keyName], $event)"
          />
          <!-- @upload="addFiles(piece[keyName], $event)" -->
        </div>
        <div
          v-else-if="piece.type === 'files'"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
          class="nohighlight"
        >
          <p v-if="tmp_data[piece[keyName]].length === 0 && editable === false" class="instructions">
            {{ $t('noSectionDocuments') }}
          </p>
          <div class="files has-margin-top">
            <RecordFileEntry
              v-for="file in tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : []"
              :key="file.key"
              :can-edit="editable"
              :allow-classified="false"
              :file="file"
              @remove="removeFile(piece[keyName], $event)"
              @download="downloadFile"
              @preview="previewFile"
            />
          </div>
          <Uploader
            v-if="
              editable &&
                (typeOf(piece.max) === 'undefined' || (piece.max && tmp_data[piece[keyName]].length < piece.max))
            "
            :label="'Carregar ficheiros'"
            :is-docked="true"
            :layout="'minimal'"
            :reference-id="'uploader_' + piece[keyName]"
            :allowed="piece.allowed ? piece.allowed : 'pdf'"
            :clear-after="true"
            :upload-path="piece.uploadPath"
            input-id="upload_input"
            :files.sync="filesToUpload"
            :size="0"
            :new-file-context="newFileContext"
            :file-type="piece.fileType || 'file'"
            :file-code="piece.fileCode"
            class="cursor-pointer"
            @focus="focusInput(piece[keyName])"
            @blur="focusInput(piece[keyName], null, 'blur')"
            @upload="uploaded(piece, piece[keyName], $event)"
          />
          <!-- @upload="addFiles(piece[keyName], $event)" -->
        </div>
        <div
          v-else-if="piece.type === 'identity_doc'"
          class="grid gap-3 grid-cols-2 md:grid-cols-4 nohighlight"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
        >
          <div>
            <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'identity_type_' + piece[keyName]">{{
              $t('id.type.label')
            }}</label>
            <b-select
              :value="tmp_data[piece[keyName]]['type']"
              class="fw-select w-full"
              :disabled="!editable"
              :placeholder="$t('id.type.placeholder')"
              :name="'identity_type_' + piece[keyName]"
              @input="dataChanged(piece[keyName], 'id_type', $event)"
              @focus="focusInput(piece[keyName], 'id_type')"
              @blur="focusInput(piece[keyName], 'id_type', 'blur')"
            >
              <option value="cc">{{ $t('id.type.cc') }}</option>
              <option value="passport">{{ $t('id.type.passport') }}</option>
            </b-select>
          </div>
          <div>
            <label class="font-semibold text-gray-500 text-xs">{{ $t('phoneCountry') }}</label>
            <CountriesSelect
              :disabled="!editable"
              :input="tmp_data[piece[keyName]]['country']"
              @update="dataChanged(piece[keyName], 'id_country', $event)"
              @focus="focusInput(piece[keyName])"
              @blur="focusInput(piece[keyName], null, 'blur')"
            />
          </div>
          <div>
            <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'identity_code_' + piece[keyName]">
              {{ $t('id.number') }}
            </label>
            <b-input
              :value="tmp_data[piece[keyName]]['value']"
              :disabled="!editable"
              :placeholder="'Número do documento'"
              type="text"
              :name="'identity_code_' + piece[keyName]"
              @input="dataChanged(piece[keyName], 'id_number', $event)"
              @focus="focusInput(piece[keyName], 'id_number')"
              @blur="focusInput(piece[keyName], 'id_number', 'blur')"
            />
          </div>
          <div v-if="false">
            <label class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('id.emittedDate.label') }}</label>
            <b-datepicker
              :value="tmp_data[piece[keyName]]['emittedDate']"
              :disabled="!editable"
              :placeholder="$t('id.emittedDate.label')"
              icon="calendar-plus"
              @input="dataChanged(piece[keyName], 'id_emitted', $event)"
              @focus="focusInput(piece[keyName], 'id_emitted')"
              @blur="focusInput(piece[keyName], 'id_emitted', 'blur')"
            >
            </b-datepicker>
          </div>
          <div>
            <label class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('id.validDate.label') }}</label>
            <b-datepicker
              :value="tmp_data[piece[keyName]]['expire_date']"
              position="is-bottom-left"
              :disabled="!editable"
              :placeholder="$t('id.validDate.label')"
              icon="calendar-plus"
              @input="dataChanged(piece[keyName], 'id_expired', $event)"
              @focus="focusInput(piece[keyName], 'id_expired')"
              @blur="focusInput(piece[keyName], 'id_expired', 'blur')"
            >
            </b-datepicker>
          </div>
        </div>
        <div
          v-else-if="piece.type === 'phone_number'"
          class="flex gap-3 nohighlight"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
        >
          <div>
            <label class="font-semibold text-gray-500 text-xs">{{ $t('phoneCountry') }}</label>
            <PhoneCountriesSelect
              :disabled="!editable"
              :input="tmp_data[piece[keyName]]['country']"
              @update="dataChanged(piece[keyName], 'phone_number_code', $event)"
              @focus="focusInput(piece[keyName], 'phone_number_country_code')"
              @blur="focusInput(piece[keyName], 'phone_number_country_code', 'blur')"
            />
          </div>
          <div class="flex-1">
            <label class="font-semibold text-gray-500 text-xs">{{ $t('phone') }}</label>
            <b-input
              v-model="tmp_data[piece[keyName]]['value']"
              :placeholder="$t('phone')"
              :disabled="!editable"
              type="text"
              :name="tmp_data[piece[keyName]]"
              @input="dataChanged(piece[keyName], 'phone_number_main', $event)"
              @focus="focusInput(piece[keyName], 'phone_number')"
              @blur="focusInput(piece[keyName], 'phone_number', 'blur')"
            />
          </div>
        </div>
        <b-datepicker
          v-else-if="piece.type === 'date'"
          :value="tmp_data[piece[keyName]]"
          position="is-bottom-left"
          :disabled="!editable"
          :placeholder="$t('date_placeholder')"
          @input="dataChanged(piece[keyName], piece.type, $event)"
          @focus="focusInput(piece[keyName], piece.type)"
          @blur="focusInput(piece[keyName], piece.type, 'blur')"
        >
        </b-datepicker>
        <b-datetimepicker
          v-else-if="piece.type === 'datetime'"
          :value="tmp_data[piece[keyName]]"
          position="is-bottom-left"
          :disabled="!editable"
          :placeholder="$t('datetime_placeholder')"
          @input="dataChanged(piece[keyName], piece.type, $event)"
          @focus="focusInput(piece[keyName], piece.type)"
          @blur="focusInput(piece[keyName], piece.type, 'blur')"
        >
        </b-datetimepicker>
        <div
          v-else-if="piece.type === 'vat'"
          class="flex gap-3 nohighlight"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
        >
          <div>
            <label class="font-semibold text-gray-500 text-xs">{{ $t('phoneCountry') }}</label>
            <CountriesSelect
              :disabled="!editable"
              :input="tmp_data[piece[keyName]]['country']"
              @update="dataChanged(piece[keyName], 'vat_country', $event)"
              @focus="focusInput(piece[keyName])"
              @blur="focusInput(piece[keyName], null, 'blur')"
            />
          </div>
          <div class="flex-1">
            <label class="font-semibold text-gray-500 text-xs">{{ $t('vat') }}</label>
            <b-input
              v-model="tmp_data[piece[keyName]]['value']"
              :placeholder="$t('vat')"
              :disabled="!editable"
              type="text"
              :name="tmp_data[piece[keyName]]"
              @input="dataChanged(piece[keyName], 'vat_value', $event)"
              @focus="focusInput(piece[keyName], 'vat_value')"
              @blur="focusInput(piece[keyName], 'vat_value', 'blur')"
            />
          </div>
        </div>
        <div v-else-if="piece.type === 'address'" class="flex flex-col gap-2.5">
          <div class="grid gap-3 grid-cols-2 nohighlight">
            <div>
              <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'address_street_' + piece[keyName]">
                {{ $t('address.street') }}
              </label>
              <TextInput
                :value="tmp_data[piece[keyName]]['address']"
                :placeholder="$t('address.street')"
                :disabled="!editable"
                @input="dataChanged(piece[keyName], 'address_street', $event)"
                @focus="focusInput(piece[keyName], 'address_street')"
                @blur="focusInput(piece[keyName], 'address_street', 'blur')"
              ></TextInput>
            </div>
            <div>
              <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'address_number_' + piece[keyName]">
                {{ $t('address.number') }}
              </label>
              <TextInput
                :value="tmp_data[piece[keyName]]['number']"
                :placeholder="$t('address.number')"
                :disabled="!editable"
                @input="dataChanged(piece[keyName], 'address_number', $event)"
                @focus="focusInput(piece[keyName], 'address_number')"
                @blur="focusInput(piece[keyName], 'address_number', 'blur')"
              ></TextInput>
            </div>
            <div>
              <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'address_postal_code_' + piece[keyName]">
                {{ $t('address.state') }}
              </label>
              <TextInput
                :value="tmp_data[piece[keyName]]['state']"
                :placeholder="$t('address.state')"
                :disabled="!editable"
                @input="dataChanged(piece[keyName], 'address_state', $event)"
                @focus="focusInput(piece[keyName], 'address_state')"
                @blur="focusInput(piece[keyName], 'address_state', 'blur')"
              ></TextInput>
            </div>
            <div>
              <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'address_postal_code_' + piece[keyName]">
                {{ $t('address.postal_code') }}
              </label>
              <TextInput
                :value="tmp_data[piece[keyName]]['postal_code']"
                :placeholder="$t('address.postal_code')"
                :disabled="!editable"
                @input="dataChanged(piece[keyName], 'address_postal_code', $event)"
                @focus="focusInput(piece[keyName], 'address_postal_code')"
                @blur="focusInput(piece[keyName], 'address_postal_code', 'blur')"
              ></TextInput>
            </div>
            <div>
              <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'address_locality_' + piece[keyName]">
                {{ $t('address.locality') }}
              </label>
              <TextInput
                :value="tmp_data[piece[keyName]]['locality']"
                :placeholder="$t('address.locality')"
                :disabled="!editable"
                @input="dataChanged(piece[keyName], 'address_locality', $event)"
                @focus="focusInput(piece[keyName], 'address_locality')"
                @blur="focusInput(piece[keyName], 'address_locality', 'blur')"
              ></TextInput>
            </div>
            <div>
              <label class="font-semibold text-gray-500 mb-1 text-xs" :for="'address_country_' + piece[keyName]">
                {{ $t('address.country') }}
              </label>
              <CountriesSelect
                :disabled="!editable"
                :input="tmp_data[piece[keyName]]['country']"
                @update="dataChanged(piece[keyName], 'address_country', $event)"
                @focus="focusInput(piece[keyName], 'address_country')"
                @blur="focusInput(piece[keyName], 'address_country', 'blur')"
              />
            </div>
          </div>
        </div>
        <EmailInput
          v-else-if="piece.type === 'email'"
          :id="piece[keyName]"
          :value="tmp_data[piece[keyName]] ? tmp_data[piece[keyName]] : ''"
          :error="errors && errors[piece[keyName]] ? errors[piece[keyName]] : ''"
          :disabled="!editable"
          :language="language"
          @input="dataChanged(piece[keyName], piece.type, $event)"
          @focus="focusInput(piece[keyName])"
          @blur="focusInput(piece[keyName], null, 'blur')"
        />
        <div v-else-if="piece.type === 'switch'" class="flex flex-col">
          <div class="flex items-start">
            <b-switch
              v-model="tmp_data[piece[keyName]]"
              :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
              class="nohighlight flex-1"
              :disabled="!editable"
              @input="dataChanged(piece[keyName], piece.type, $event)"
              @focus="focusInput(piece[keyName])"
              @blur="focusInput(piece[keyName], null, 'blur')"
            >
              <div v-html="piece.label && piece.label[language] ? piece.label[language] : piece.label"></div>
            </b-switch>
            <div
              v-if="piece.required === true"
              class="bg-gray-800 text-gray-100 rounded-full px-2 py-0.5 text-xs font-bold nohighlight"
            >
              {{ mandatoryString }}
            </div>
            <div
              v-else-if="piece.required === false"
              class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold nohighlight"
            >
              {{ optionalString }}
            </div>
          </div>
          <div
            v-if="piece.instructions && piece.instructions[language]"
            class="text-sm pl-14 mt-0.5 text-gray-500"
            v-html="
              piece.instructions && piece.instructions[language] ? piece.instructions[language] : piece.instructions
            "
          ></div>
        </div>
        <div v-else-if="piece.type === 'bool'" class="flex flex-col">
          <div class="flex items-start">
            <div class="w-28">
              <OnOffOption
                :disabled="!editable"
                :options="['Sim', 'Não']"
                :selected-option="
                  tmp_data[piece[keyName]] == true ? 'Sim' : tmp_data[piece[keyName]] == false ? 'Não' : null
                "
                @input="dataChanged(piece[keyName], piece.type, $event)"
              />
            </div>
            <div
              class="flex-1"
              v-html="piece.label && piece.label[language] ? piece.label[language] : piece.label"
            ></div>
            <div
              v-if="piece.required === true"
              class="bg-gray-800 text-gray-100 rounded-full px-2 py-0.5 text-xs font-bold nohighlight"
            >
              {{ mandatoryString }}
            </div>
            <div
              v-else-if="piece.required === false"
              class="bg-gray-100 text-gray-600 rounded-full px-2 py-0.5 text-xs font-bold nohighlight"
            >
              {{ optionalString }}
            </div>
          </div>
          <div
            v-if="piece.instructions && piece.instructions[language]"
            class="text-sm pl-28 mt-0.5 text-gray-500"
            v-html="
              piece.instructions && piece.instructions[language] ? piece.instructions[language] : piece.instructions
            "
          ></div>
        </div>
        <b-select
          v-else-if="piece.type === 'select'"
          v-model="tmp_data[piece[keyName]]"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
          name="type"
          :disabled="!editable"
          expanded
          class="fw-select nohighlight"
          @input="dataChanged(piece[keyName], piece.type, $event)"
          @focus="focusInput(piece[keyName])"
          @blur="focusInput(piece[keyName], null, 'blur')"
        >
          <option v-for="(type_key, k) in piece.options.options" :key="piece[keyName] + '_' + k" :value="type_key.key">
            {{ type_key.text && type_key.text[language] ? type_key.text[language] : type_key.text }}
          </option>
        </b-select>
        <div
          v-else-if="piece.type === 'formatted_text'"
          class="html-editor nohighlight"
          :class="{ error: errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable }"
        >
          <editor-menu-bar v-slot="{ commands, isActive, focused }" :editor="editors[piece[keyName]]">
            <div class="menubar" :class="{ 'is-focused': focused }">
              <button class="button is-text is-small" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                <icon name="bold" />
              </button>

              <button
                class="button is-text is-small"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
              >
                <icon name="italic" />
              </button>

              <button
                class="button is-text is-small"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
              >
                <icon name="underline" />
              </button>

              <button
                class="button is-text is-small"
                :class="{ 'is-active': isActive.paragraph() }"
                @click="commands.paragraph"
              >
                <icon name="paragraph" />
              </button>

              <button class="button is-text is-small" @click="commands.undo">
                <icon name="undo" />
              </button>

              <button class="button is-text is-small" @click="commands.redo">
                <icon name="redo" />
              </button>
            </div>
          </editor-menu-bar>
          <editor-content :editor="editors[piece[keyName]]" @focus="focusInput(piece[keyName])" />
        </div>
        <MultipleChoice
          v-else-if="piece.type === 'multiple_choice'"
          :ref="'multiple_choice_' + piece[keyName]"
          :options="piece.options.options"
          class="mt-2.5 nohighlight"
          :max="piece.max"
          :language="language"
          :saving="saving"
          :mode="'normal'"
          :random="false"
          :disabled="!editable"
          :selected="tmp_data[piece[keyName]]"
          @selected="dataChanged(piece[keyName], piece.type, $event, true)"
        ></MultipleChoice>
        <div v-else-if="piece.type === 'image'" class="overflow-hidden rounded-2xl mt-2.5 nohighlight">
          <img
            class="w-full cursor-pointer"
            :src="getImageViewUrl(piece.file, 'max800')"
            @click="openImageFullscreen(piece.file)"
          />
        </div>
        <div
          v-else-if="piece.type === 'image_direct'"
          class="overflow-hidden rounded-2xl bg-gray-100 mt-2.5 nohighlight"
          style="min-height: 90px"
        >
          <img :src="piece.directUrl" class="w-full" />
        </div>
        <div v-else-if="piece.type === 'video'" class="nohighlight">
          <video-player
            :ref="'video-' + piece[keyName]"
            :src="getFileViewUrl(piece.file)"
            :type="piece.file.mimetype"
          />
        </div>
        <TranslatedInput
          v-else-if="
            piece.type === 'textarea_input' ||
              piece.type === 'multilanguage_text_input' ||
              piece.type === 'multilanguage_textarea_input'
          "
          class="nohighlight"
          :disabled="!editable"
          :value="tmp_data[piece[keyName]]"
          :multiline="piece.type === 'textarea_input' || piece.type === 'multilanguage_textarea_input'"
          :autogrow="true"
          :min-height="200"
        >
        </TranslatedInput>
        <div v-if="piece.type === 'group' && editable" class="nohighlight">
          <div v-for="(subpiece, ss) in piece.content" :key="'subpiece_error_' + ss">
            <div
              v-if="errors && errors[subpiece[keyName]] && errors[subpiece[keyName]].length > 0"
              class="text-sm mt-1.5"
              style="color: #f15f1f"
            >
              {{ errors[subpiece[keyName]] }}
            </div>
          </div>
        </div>
        <div
          v-if="piece.instructions && piece.instructions[language] && piece.type != 'bool'"
          class="text-sm my-1 text-gray-500"
          v-html="
            piece.instructions && piece.instructions[language] ? piece.instructions[language] : piece.instructions
          "
        ></div>
        <div
          v-if="errors && errors[piece[keyName]] && errors[piece[keyName]].length > 0 && editable"
          class="text-sm mt-1.5 nohighlight error-form"
          style="color: #f15f1fff"
        >
          {{ errors[piece[keyName]] }}
        </div>
        <div v-else-if="piece.message" class="text-xs mt-1.5 nohighlight">{{ piece.message }}</div>
        <ExpandList
          v-if="piece.type === 'person_input'"
          class="nohighlight"
          :add-title="piece.add_label ? piece.add_label : 'Adicionar pessoa'"
          :empty="tmp_data[piece[keyName]].length === 0"
          :show-add-items="piece.options.max != 0 && tmp_data[piece[keyName]].length !== piece.options.max"
          @add-element="choosePeople(piece[keyName], piece.modal.title, piece.modal.instructions, piece.max)"
        >
          <template #list>
            <Person
              v-for="(person, pr) in tmp_data[piece[keyName]]"
              :key="person.key"
              class="px-3 border-b border-gray-100"
              no-style
              :person="person"
              :selectable="false"
              :clickable="false"
            >
              <template #options>
                <fw-button
                  type="simple"
                  size="sm"
                  class="flex items-center gap-2 font-semibold opacity-50"
                  label="Remover pessoa"
                  @click.native="removePerson(piece[keyName], pr)"
                >
                  <fw-icon-trash class="w-5 h-5" /> Remover
                </fw-button>
              </template>
            </Person>
          </template>
        </ExpandList>
        <ExpandList
          v-else-if="piece.type === 'meeting_input'"
          class="nohighlight"
          add-title="Adicionar Reunião"
          :empty="tmp_data[piece[keyName]].length === 0"
          :show-add-items="tmp_data[piece[keyName]].length !== piece.max"
          @add-element="newMeeting(piece[keyName])"
        >
          <template #list>
            <div
              v-for="(meeting, m) in tmp_data[piece[keyName]]"
              :key="'meeting_' + m"
              class="p-5 border-b border-gray-100 flex justify-end items-center"
            >
              <div class="flex-1">
                <fw-heading size="h4"
                  >{{ meeting.meeting_datetime | formatDayWithTime }}
                  <div class="w-2 h-2 bg-gray-200 mx-1.5 rounded-full inline-block mb-0.5"></div>
                  {{ meeting.text }}</fw-heading
                >
              </div>

              <fw-button
                type="simple"
                size="sm"
                class="flex items-center gap-2 font-semibold opacity-50"
                label="Editar reunião"
                @click.native="editMeeting(piece[keyName], m)"
              >
                <fw-icon-edit class="w-5 h-5" /> Editar
              </fw-button>
              <fw-button
                type="simple"
                size="sm"
                class="flex items-center gap-2 font-semibold opacity-50"
                label="Eliminar reunião"
                @click.native="deleteMeeting(piece[keyName], m)"
              >
                <fw-icon-trash class="w-5 h-5" /> Eliminar
              </fw-button>
            </div>
          </template>
        </ExpandList>
        <ExpandList
          v-else-if="piece.type === 'custom_object' || piece.type === 'custom_objects'"
          class="nohighlight"
          :add-title="'Adicionar entrada'"
          :empty="tmp_data[piece[keyName]].length === 0"
          :show-add-items="
            editable &&
              (piece.options.max == 0 ||
                (piece.options.max != 0 && tmp_data[piece[keyName]].length !== piece.options.max))
          "
          :overflow="false"
          @add-element="addNewCustomObject(p, piece[keyName])"
        >
          <template #list>
            <div class="flex flex-col gap-1 custom_object_list">
              <Container
                group-name="topics"
                drop-class="card-ghost-drop"
                :drop-placeholder="dropPlaceholderOptions"
                :should-animate-drop="() => true"
                :get-child-payload="index => getChildPayload(piece[keyName], index)"
                drag-handle-selector=".drag-handle-new-form"
                class="flex flex-col gap-1"
                @drop="onDropSection($event, piece[keyName])"
              >
                <Draggable
                  v-for="(entry, pr) in tmp_data[piece[keyName]]"
                  :key="entry.key"
                  class="bg-gray-100 rounded-lg relative"
                >
                  <div v-if="editable" class="drag-handle-new-form top-2.5 -left-3 z-10">
                    <svg
                      class="fill-current h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 16"
                      width="10"
                      height="16"
                    >
                      <path
                        fill-opacity="0.13"
                        d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                      />
                    </svg>
                  </div>
                  <CustomEntry :data="entry" :schema="piece.options.schema">
                    <template #options>
                      <fw-button
                        v-if="editable"
                        type="x-light"
                        size="sm"
                        class="flex items-center gap-2 font-semibold opacity-50"
                        label="Editar entrada"
                        @click.native="editCustomObject(p, pr, piece[keyName])"
                      >
                        <fw-icon-edit class="w-5 h-5" /> Editar
                      </fw-button>
                      <fw-button
                        v-if="editable"
                        type="x-light"
                        size="sm"
                        class="flex items-center gap-2 font-semibold opacity-50"
                        label="Remover entrada"
                        @click.native="removeCustomObject(piece[keyName], pr)"
                      >
                        <fw-icon-trash class="w-5 h-5" /> Remover
                      </fw-button>
                    </template>
                  </CustomEntry>
                </Draggable>
              </Container>
            </div>
          </template>
        </ExpandList>
        <div v-if="debug" class="bg-yellow-100 p-3 rounded-md mt-2 text-xs">
          <b>Question:</b> {{ piece }}<br />
          <b>Local value:</b> {{ tmp_data[piece.key] }}<br />
          <b>Saving question:</b> {{ saved.has(piece.key) }}<br />
          <b>Question is dirty:</b> {{ dirty.has(piece.key) }}<br />
          <b>Question has failed:</b> {{ failed.has(piece.key) }}<br />
          <b>Remote data (after saving):</b> <span :ref="'debug_remote' + piece[keyName]">--</span>
        </div>
        <div v-if="dirty.has(piece.key)" class="absolute nohighlight top-3 right-3">
          <fw-icon-loading v-if="false" class="w-5 h-5" />
          <div class="h-2 w-2 bg-yellow-500 rounded-full"></div>
        </div>
        <div
          class="absolute right-3 top-2 p-1 px-2 bg-white backdrop-blur-md bg-opacity-80 items-center rounded-full text-primary font-semibold text-xs flex transition-all nohighlight"
          :class="{
            'opacity-0': !saved.has(piece.key) || dirty.has(piece.key)
          }"
        >
          Resposta guardada
        </div>
        <div
          class="absolute transition-all top-6 right-6"
          :class="{
            'opacity-0': !failed.has(piece.key)
          }"
        >
          <b-tooltip label="Pergunta não guardada" type="is-dark" position="is-left">
            <fw-icon-error-warning class="w-6 h-6 text-red-600" />
          </b-tooltip>
        </div>
      </div>
    </div>

    <b-modal
      :active="activeModal !== null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="cancelModal"
      :custom-class="'rounded-buefy-modal nohighlight'"
    >
      <ChoosePeopleModal
        v-if="activeModal === 'person_input'"
        :title="modalTitle"
        :instructions="modalInstructions"
        :multiselect="multiselectModal"
        :selected="selectedModalValues"
        @selected="selectedModal"
        @close="cancelModal"
      ></ChoosePeopleModal>
      <MeetingModal
        v-else-if="activeModal === 'meeting_input'"
        :meeting-data="currentEditing"
        :subject-options="meetingsSubjectOptions"
        :virtual-meeting="{}"
        @save="saveModal"
        @close="cancelModal"
      ></MeetingModal>
      <CustomObjectModal
        v-else-if="activeModal === 'custom_object'"
        :schema="customObjectSchema"
        :data="customObjectData"
        @close="cancelModal"
        @save="saveInnerForm"
      />
    </b-modal>

    <div v-show="showImageFullScreen" class="z-40 fixed top-16 left-0 right-0 bottom-0 flex bg-black nohighlight">
      <div
        class="cursor-pointer w-12 h-12 fixed z-50 top-20 right-5 rounded-full flex justify-center items-center text-white bg-black bg-opacity-30"
        @click="closeFullScreen"
      >
        <fw-icon-close class="h-8 w-8" />
      </div>
      <div class="flex-1">
        <v-zoomer v-if="activeFullscreenImage" class="h-full w-full">
          <img :src="activeFullscreenImage" class="h-full w-full object-contain" />
        </v-zoomer>
      </div>
      <div v-if="false && fullScreenQuestion != null" class="h-full w-1/5 bg-white shrink-0">Pergunta</div>
    </div>
  </div>
</template>

<script>
import VueZoomer from 'vue-zoomer'
import TextInput from './TextInput'
import EmailInput from './EmailInput'
import CountriesSelect from './CountriesSelect'
import RecordFileEntry from './RecordFileEntry'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import StorageUploader from '@/fw-modules/fw-core-vue/storage/components/Uploader.vue'
import PhoneCountriesSelect from './PhoneCountriesSelect'
//import ServiceBuckets from '../../../buckets/services/ServiceBuckets'
import MultipleChoice from './MultipleChoice'
import ServiceStorage from '../../../storage/services/ServiceStorage'
import VideoPlayer from '@/fw-modules/fw-core-vue/ui/components/video/VideoPlayer'
import MeetingModal from '../modals/MeetingModal'
import ChoosePeopleModal from '../modals/ChoosePeopleModal'
import CustomObjectModal from '../modals/CustomObjectModal'
import ExpandList from '../lists/ExpandList'
import Person from '../cards/PersonBase'
import TranslatedInput from './TranslatedInput'
import HtmlEditor from './HtmlEditor'
import CustomEntry from './CustomEntry'
import OnOffOption from './onoffOption'
import { Container, Draggable } from 'vue-dndrop'
export default {
  name: 'FormSection',
  components: {
    MultipleChoice,
    TextInput,
    CountriesSelect,
    RecordFileEntry,
    Uploader,
    PhoneCountriesSelect,
    VideoPlayer,
    MeetingModal,
    ChoosePeopleModal,
    ExpandList,
    Person,
    TranslatedInput,
    StorageUploader,
    HtmlEditor,
    VZoomer: VueZoomer.Zoomer,
    CustomObjectModal,
    EmailInput,
    CustomEntry,
    Draggable,
    Container,
    OnOffOption
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Object
    },
    data: {
      type: Object,
      required: true
    },
    hasLogListener: {
      type: Boolean,
      default: false
    },
    fontSizeRatio: {
      type: Number,
      default: 1
    },
    keyName: {
      type: String,
      default: 'key'
    },
    bucketId: {
      type: String,
      default: null
    },
    folderId: {
      type: String,
      default: null
    },
    language: {
      type: String,
      default: 'pt'
    },
    languagesOptions: {
      type: Array,
      default: function() {
        return ['pt', 'en']
      }
    },
    id: {
      type: String,
      default: 'fw_form'
    },
    realtimeDatachange: {
      type: Boolean,
      default: false
    },
    debug: {
      type: Boolean,
      default: false
    },
    hasSection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editors: {},
      tmp_data: null,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      //custom object
      customObjectSchema: null,
      customObjectData: null,
      customObjectPosition: {
        s: 0,
        i: 0,
        o: 0 //option, only if editing a custom object
      },
      //end custom object
      activeFullscreenImage: null,
      fullScreenQuestion: null,
      currentEditing: null,
      selectedModalValues: [], //preselected values
      modalTitle: null,
      modalMaxLen: 0,
      modalInstructions: null,
      multiselectModal: false, //true if max > 1
      activeModal: null, //tipo de input?
      activeModalID: null, //id do input
      filesToUpload: [],
      currentfocus_id: null,
      currentfocus_subtype: null,
      restore_context_menu: null,
      ctrlDown: false,
      listeners: [],
      startInput: '', //to count words per second we store here the initial input value
      debouceTimer: null,
      dirty: new Set(), //array of dirty fields to be saved
      saved: new Set(), //array of recently saved fields
      failed: new Set(), //array of recently failed fields
      saving: false
    }
  },
  computed: {
    mandatoryString: function() {
      return this.language == 'pt' ? 'Obrigatório' : 'Mandatory'
    },
    optionalString: function() {
      return this.language == 'pt' ? 'Opcional' : 'Optional'
    },
    isDigitalPad() {
      return process.env.VUE_APP_KEY == 'ucdigitalpad'
    },
    showImageFullScreen() {
      return this.activeFullscreenImage !== null
    },
    computedFontSize() {
      return this.fontSizeRatio * 100 + '%'
    },
    newFileContext() {
      return { bucket: this.bucketId, folder: this.folderId }
    },
    user() {
      return this.$store.getters.getUser
    }
  },
  created() {
    if (this.hasLogListener) {
      const ctrlKey = 17,
        cmdKey = 91,
        vKey = 86,
        cKey = 67

      this.registerListener('document', 'keydown', key => {
        let code = key.keyCode
        if (code === ctrlKey || code === cmdKey) {
          this.ctrlDown = true
        } else if (this.ctrlDown && code === cKey) {
          //copy event
          this.sendLogs({
            time: new Date(),
            action: 'copy',
            inputID: this.currentfocus_id ? this.currentfocus_id : 'window',
            subinput: this.currentfocus_subtype
          })
        } else if (this.ctrlDown && code === vKey) {
          //paste event
          this.sendLogs({
            time: new Date(),
            action: 'paste',
            inputID: this.currentfocus_id ? this.currentfocus_id : 'window',
            subinput: this.currentfocus_subtype
          })
        }
      })

      this.registerListener('document', 'keyup', key => {
        let code = key.keyCode
        if (code === ctrlKey || code === cmdKey) {
          this.ctrlDown = false
        }
      })

      this.registerListener('window', 'focus', () => {
        this.sendLogs({
          time: new Date(),
          action: 'focus',
          inputID: 'window'
        })
      })

      this.registerListener('window', 'blur', () => {
        this.sendLogs({
          time: new Date(),
          action: 'blur',
          inputID: 'window'
        })
      })

      this.registerListener('document', 'fullscreenchange', () => {
        if (document.fullscreenElement) {
          this.sendLogs({
            time: new Date(),
            action: 'enter_full_screen',
            inputID: document.fullscreenElement.id
          })
          //console.log(`Element: ${document.fullscreenElement.id} entered full-screen mode.`);
        } else {
          this.sendLogs({
            time: new Date(),
            action: 'leaving_full_screen',
            inputID: 'window'
          })
        }
      })
    }

    if (this.disableContextMenu) {
      window.oncontextmenu = function() {
        //avoid right click / context menu
        return false
      }
    }
    console.log('FormSection created', this.section)
    this.tmp_data = this.data //JSON.parse(JSON.stringify(this.data))
  },

  beforeDestroy() {
    //Destroy all editors
    for (const [key] of Object.entries(this.editors)) {
      this.editors[key].destroy()
    }

    //Remove events listeners
    if (this.listeners.length > 0) {
      for (let i = 0; i < this.listeners.length; i++) {
        let listener = this.listeners[i]
        if (listener.element === 'window') {
          window.removeEventListener(listener.event, listener.action)
        } else if (listener.element === 'document') {
          document.removeEventListener(listener.event, listener.action)
        } else {
          throw 'Not implemented listener element'
        }
      }
    }

    //RESTORE right click
    if (this.disableContextMenu) {
      window.oncontextmenu = null
    }

    if (this.debouceTimer !== null) {
      clearTimeout(this.debouceTimer)
      this.emitDataChanged() //there is data that was not processed!
    }
  },

  methods: {
    getChildPayload(key, index) {
      //console.log('getChildPayload', p, s, index)
      return this.tmp_data[key][index]
    },
    onDropSection(dropResult, key) {
      console.log('onDropSection', dropResult)
      //this.endpoint(dropResult.payload.key, dropResult.addedIndex)
      let result = this.applyDrag(this.tmp_data[key], dropResult)
      console.log('applyDrag', result)
      this.tmp_data[key] = result
      this.$emit('saved', result)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },

    removeCustomObject(input, index) {
      this.$buefy.dialog.confirm({
        title: 'Remover entrada',
        message: 'Tem a certeza que deseja remover esta entrada?',
        confirmText: 'Remover',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.tmp_data[input].splice(index, 1)
          this.emitDataChanged()
        }
      })
    },
    saveInnerForm(data) {
      let position = this.customObjectPosition
      let inputKey = this.section.content[position.i].key
      //process data

      if (position.o >= 0) {
        //we are editing a custom object
        this.tmp_data[inputKey][position.o] = data
      } else {
        this.tmp_data[inputKey].push(data)
      }
      this.cancelModal()
    },
    addNewCustomObject(i, dataId) {
      console.log('addNewCustomObject', i, dataId)
      this.customObjectPosition = { i: i }
      let schema = this.section.content[i].options.schema
      console.log('schema', schema)
      this.customObjectSchema = schema
      this.customObjectData = null
      this.activeModal = 'custom_object'
    },
    editCustomObject(i, o, dataId) {
      console.log('editCustomObject', i, dataId)
      this.customObjectPosition = { i: i, o: o }
      let schema = this.section.content[i].options.schema
      console.log('schema', schema)
      this.customObjectSchema = schema
      this.customObjectData = this.tmp_data[dataId][o]
      this.activeModal = 'custom_object'
    },
    typeOf(element) {
      return typeof element
    },
    choosePeople(id, title, instructions, max = 1) {
      this.activeModal = 'person_input'
      this.activeModalID = id
      this.selectedModalValues = this.tmp_data[id] //preselected values
      this.modalTitle = title
      this.modalInstructions = instructions
      this.multiselectModal = max > 1
      this.modalMaxLen = max
    },
    removePerson(id, pos) {
      let personKey = this.tmp_data[id][pos].key
      this.tmp_data[id].splice(pos, 1)

      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'removePerson',
          inputID: id,
          elements: [personKey]
        })
      }
    },
    newMeeting(id) {
      this.currentEditing = null
      this.activeModal = 'meeting_input'
      this.activeModalID = id
    },
    editMeeting(id, pos) {
      this.currentEditing = this.tmp_data[id][pos]
      this.activeModal = 'meeting_input'
      this.activeModalID = id
    },
    deleteMeeting(id, pos) {
      let meetingKey = this.tmp_data[id][pos].key
      this.tmp_data[id].splice(pos, 1)

      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'removeMeeting',
          inputID: id,
          elements: [meetingKey]
        })
      }
    },
    saveModal(value) {
      let action = 'edited_'
      if (this.currentEditing) {
        this.tmp_data[this.activeModalID][this.currentEditing] = value
      } else {
        action = 'added_'
        this.tmp_data[this.activeModalID].push(value)
      }
      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: action + this.activeModal,
          inputID: this.activeModalID,
          elements: []
        })
      }
    },
    selectedModal(value) {
      let newvalue = this.tmp_data[this.activeModalID]
      let excessLen = value.length + newvalue.length - this.modalMaxLen
      let start = newvalue.length - excessLen
      if (start < 0) {
        excessLen -= newvalue.length
        newvalue = []
        value.splice(value.length - excessLen, excessLen)
      } else {
        newvalue.splice(start, excessLen)
      }
      this.dataChanged(this.activeModalID, this.activeModal, newvalue.concat(value))
    },
    cancelModal() {
      this.activeModal = null
    },
    playerReady(player) {
      //Deactivate download and right click on the player
      player.el_.childNodes[0].setAttributeNS(null, 'controlsList', 'nodownload')
      player.el_.childNodes[0].setAttribute('oncontextmenu', 'return false;')
    },
    getFileViewUrl(file) {
      let publicToken = file.token || this.user.token
      return ServiceStorage.getFileUrl(file, publicToken)
    },
    getImageViewUrl(file, size) {
      let publicToken = file.token || this.user.token
      return ServiceStorage.getImageViewUrl(file, size, publicToken)
    },
    async uploadedToStorage(piece, id, files) {
      console.log(piece, id, files)
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          //const data = { files: [file.response.data.file.key] }
          //if (file.context.folder) data.folder_key = file.context.folder

          //const response = await ServiceBuckets.addItemsToBucket(file.context.bucket, data)
          //console.log('response', response)
          //if (file.context.bucket == this.bucketId && file.context.folder == this.folderId && response.new_items) {
          //this.addItems(response.new_items)
          //this.showFullDragZoneUploader = false
          //tmp_data[piece[this.keyName]]
          if (file.response.status === 'success') {
            //fileKeys = fileKeys.concat(response.new_items.map(file => file.key))

            /*

            file['title'] = file.filename
            newfiles.push(file)
            */
            let fileData = file.response.data.file
            fileData['title'] = fileData.filename
            newfiles.push(fileData)
            //let newdata = this.tmp_data[id].concat(file.response.data.file)
          }

          //}
        }
      }

      if (piece.afterUpload) {
        await piece.afterUpload(newfiles.map(file => file.key))
      }

      this.tmp_data[id] = this.tmp_data[id].concat(newfiles)
      this.emitDataChanged()
      this.emitAtomicDataChanged(id, 'upload', this.tmp_data[id])
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'upload',
          inputID: id,
          elements: newfiles.map(file => file.key)
        })
      }
    },
    async uploaded(piece, id, files) {
      let newfiles = []
      for (const file of files) {
        if (file.response.data) {
          //const data = { files: [file.response.data.file.key] }
          //if (file.context.folder) data.folder_key = file.context.folder

          //const response = await ServiceBuckets.addItemsToBucket(file.context.bucket, data)
          //console.log('response', response)
          //if (file.context.bucket == this.bucketId && file.context.folder == this.folderId && response.new_items) {
          //this.addItems(response.new_items)
          //this.showFullDragZoneUploader = false
          //tmp_data[piece[this.keyName]]
          if (file.response.status === 'success') {
            //fileKeys = fileKeys.concat(response.new_items.map(file => file.key))
            newfiles.push(file.response.data.file)
            //let newdata = this.tmp_data[id].concat(file.response.data.file)
          }

          //}
        }
      }

      if (piece.afterUpload) {
        await piece.afterUpload(newfiles.map(file => file.key))
      }

      this.tmp_data[id] = this.tmp_data[id].concat(newfiles)
      this.emitDataChanged()
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'upload',
          inputID: id,
          elements: newfiles.map(file => file.key)
        })
      }
    },
    focusInput(inputID, subinput, action = 'focus') {
      if (this.hasLogListener) {
        if (action === 'focus') {
          this.currentfocus_id = inputID
          this.currentfocus_subtype = subinput
          this.startInput = this.tmp_data[inputID]

          let data = {
            time: new Date(),
            action: action,
            inputID: inputID
          }
          if (subinput) {
            data['subinput'] = subinput
          }

          this.sendLogs(data)
        } /* else {
          this.currentfocus_id = null
          this.currentfocus_subtype = null
          //this.startInput = ''
        }*/
      }
    },
    sendLogs(data) {
      if (this.hasLogListener) {
        this.$emit('logs', data)
      }
    },
    async downloadFile(file) {
      this.$emit('download', file)
      /*let url
      if (file.url) {
        const response = await ServiceStorage.getFiles([file.key])
        url = file.url
        url = window.URL.createObjectURL(new Blob([response]))
      } else if (file.url_format) {
        url = this.getFileViewUrl(file)
      } else {
        //const response = await this.api.getFile(file.key, file.filename)
        //url = window.URL.createObjectURL(new Blob([response]))
        const response = await ServiceStorage.getFiles([file.key])
        console.log('DOWNLOAD FILE', response)
        url = this.getFileViewUrl(response)
      }

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute('download', file.filename)
      document.body.appendChild(link)
      link.click()*/
    },
    async previewFile(file) {
      this.$emit('preview', file)
    },
    saveFile(id, file) {
      console.log('save file', id, file)
      if (this.hasLogListener) {
        this.sendLogs({
          time: new Date(),
          action: 'removeFile',
          inputID: id,
          elements: [file.key]
        })
      }
      if (this.showSavingIndicators) {
        this.dirty.add(id)
      }
      this.emitAtomicDataChanged(id, 'upload', this.tmp_data[id])
      this.emitDataChanged()
    },

    removeFile(id, file) {
      //console.log('file', file)
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          console.log('DELETE FILE1')
          for (let i = 0; i < this.tmp_data[id].length; i++) {
            let f = this.tmp_data[id][i]
            if (f.key === file.key) {
              console.log('DELETE FILE2')
              this.$emit('delete-file', file)
              this.tmp_data[id].splice(i, 1)
            }
          }

          //this.emitDataChanged()
          if (this.hasLogListener) {
            this.sendLogs({
              time: new Date(),
              action: 'removeFile',
              inputID: id,
              elements: [file.key]
            })
          }
          if (this.showSavingIndicators) {
            this.dirty.add(id)
          }
          this.emitDataChanged()
          this.emitAtomicDataChanged(id, 'upload', this.tmp_data[id])

          //const fileDeleted = await this.api.deleteFile(file.key)
          //if (fileDeleted) {
          //this.doSaveApplication(true)
          //  this.dataChanged()
          //}
        }
      })
    },
    updateFiles(id, others) {
      console.log('updateFiles', id, 'others', others)
    },
    emitDataChanged() {
      this.$emit('changed', this.tmp_data)
    },
    emitAtomicDataChanged(id, type, value) {
      this.$emit('atomic-changed', {
        id: id,
        type: type,
        value: value
      })
    },
    dataChanged(id, type, value, forceImediate = false) {
      console.log('dataChanged: id', id, 'type', type, 'value', value)
      let action = 'writing'
      if (type === 'phone_number_main') {
        this.tmp_data[id]['value'] = value
      } else if (type === 'vat_country') {
        this.tmp_data[id]['country'] = value.key
        action = 'selecting_vat_country'
      } else if (type === 'vat_value') {
        this.tmp_data[id]['value'] = value
        action = 'writing_vat_value'
      } else if (type === 'address_street') {
        this.tmp_data[id]['address'] = value
        action = 'writing_address_street'
      } else if (type === 'address_postal_code') {
        this.tmp_data[id]['postal_code'] = value
        action = 'writing_address_postal_code'
      } else if (type === 'address_locality') {
        this.tmp_data[id]['locality'] = value
        action = 'writing_address_locality'
      } else if (type === 'address_country') {
        this.tmp_data[id]['country'] = value.key
        action = 'selecting_address_country'
      } else if (type === 'address_number') {
        this.tmp_data[id]['number'] = value
        action = 'writing_address_number'
      } else if (type === 'address_state') {
        this.tmp_data[id]['state'] = value
        action = 'writing_address_state'
      } else if (type === 'phone_number_country') {
        this.tmp_data[id]['country'] = value.key
        action = 'selecting_phone_country'
      } else if (type === 'phone_number_code') {
        //OLD VERSION: this.tmp_data[id]['code'] = value.code
        /*
        {
          code: '+351',
          country: {
            code: '+351',
            key: 'PT',
            title: 'Portugal',
          },
          number: '',
        }
         */

        this.tmp_data[id]['country'] = value.key
        this.tmp_data[id]['code'] = value.code
        action = 'selecting_phone_country_code'
      } else if (type === 'country_input') {
        this.tmp_data[id] = value.key //.key
        action = 'selecting_country'
      } else if (type === 'id_type') {
        this.tmp_data[id]['type'] = value
        action = 'selecting_id_doc_type'
      } else if (type === 'id_country') {
        this.tmp_data[id]['country'] = value.key
      } else if (type === 'id_number') {
        this.tmp_data[id]['value'] = value
        action = 'writing_id_doc_number'
      } else if (type === 'id_expired') {
        this.tmp_data[id]['expire_date'] = value
        action = 'selecting_id_doc_expire_date'
      } else if (type === 'files') {
        action = 'uploaded'
        //this.tmp_data[id] = value
      } else if (type === 'id_emitted') {
        this.tmp_data[id]['emission_date'] = value
        action = 'selecting_id_doc_emitted_date'
      } else if (type === 'select') {
        action = 'selecting'
        this.tmp_data[id] = value
      } else if (type === 'multiple_choice') {
        action = 'selecting_option'
        this.tmp_data[id] = value
      } else if (type === 'bool') {
        this.tmp_data[id] = value == 1 ? false : true
      } else {
        this.tmp_data[id] = value
      }

      if (this.showSavingIndicators) {
        this.dirty.add(id)
      }

      //if needed:
      //$emit("update:modelValue", $event.target.value)
      console.log('dataChanged: action', action)
      if (action === 'writing' && this.realtimeDatachange === false && !forceImediate) {
        //debounce
        if (this.debouceTimer !== null) {
          clearTimeout(this.debouceTimer)
        }
        this.emitAtomicDataChanged(id, type, value)
        this.debouceTimer = setTimeout(() => {
          this.emitDataChanged()
          this.sendLogs({
            time: new Date(),
            action: action,
            inputID: id,
            charPerSec: this.tmp_data[id].length - this.startInput.length,
            elements: [],
            value: value
          })
          this.startInput = this.tmp_data[id]
        }, 1000)
      }

      //emit immediately
      if (!forceImediate && (action !== 'writing' || this.realtimeDatachange === true)) {
        this.emitDataChanged()
        this.emitAtomicDataChanged(id, type, value)
        if (this.hasLogListener) {
          //Only imidiatly emit if it's not writing action
          this.sendLogs({
            time: new Date(),
            action: action,
            inputID: id,
            elements: []
          })
        }
      }
      this.$store.commit('setDirtyData', true)
      if (forceImediate) {
        this.$emit('save-manual', {
          id: id,
          type: type,
          value: value
        })
      }
    },
    updateAnswers(data) {
      //New data from the server
      console.log('DATA inside the form', data)

      //loog through all the data keys and call function serverData for each
      for (const [key, value] of Object.entries(data)) {
        if (this.$refs['multiple_choice_' + key] && this.$refs['multiple_choice_' + key][0]) {
          this.$refs['multiple_choice_' + key][0].serverData(value)
        }
        if (this.$refs['debug_remote' + key] && this.$refs['debug_remote' + key][0]) {
          this.$refs['debug_remote' + key][0].innerHTML = value
        }
      }
    },
    closeFullScreen() {
      console.log('closing')
      this.activeFullscreenImage = null
      this.fullScreenQuestion = null
    },
    openImageFullscreen(imageFile, question) {
      let imgUrl = this.getImageViewUrl(imageFile, 'max2k')
      console.log('open', imgUrl)
      this.activeFullscreenImage = imgUrl
      if (question.type == 'multiple_choice') {
        this.fullScreenQuestion = question
      }
    }
  }
}
</script>

<style>
.custom_object_list .dndrop-container.vertical > .dndrop-draggable-wrapper {
  overflow: visible;
}
.drag-handle-new-form {
  @apply h-6 w-5 shadow-sm bg-white rounded border border-gray-200 items-center flex justify-center text-center absolute -left-2.5;
  cursor: grab;
}

.my-remove-tip {
  box-sizing: border-box;
  position: absolute;
  border: 1px solid #fff;
  border-radius: 3px;
  height: 20px;
  width: 40px;
  color: #fff;
  background: #444;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  overflow: visible;
}

.my-remove-tip::after {
  content: '';
  position: absolute;
  left: 16px;
  bottom: -4px;
  border-color: #444 transparent transparent;
  border-width: 4px 4px 0;
  border-style: solid;
  height: 0;
  width: 0;
}

.video-js:hover .vjs-big-play-button {
  height: 2.5em !important;
  width: 2.5em !important;
  line-height: 2.5em !important;
  border-radius: 2em !important;
  @apply bg-primary border-primary shadow-lg scale-110;
}
.video-js {
  cursor: pointer;
  @apply overflow-hidden rounded-2xl;
}
.video-js .vjs-big-play-button {
  height: 2.5em !important;
  width: 2.5em !important;
  line-height: 2.5em !important;
  border-radius: 2em !important;
  @apply bg-primary border-primary scale-90;
}

.fw-form .file-uploads {
  @apply w-full rounded;
}
.fw-select .select {
  @apply rounded w-full;
}
.fw-select .select select {
  @apply w-full;
}
.fw-select .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
  top: 60%;
}

.fw-select:hover .select:not(.is-multiple):not(.is-loading)::after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}

.fw-select:hover .select:after {
  color: #999;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  transform: rotate(0deg);
}
.fw-select:hover .select select {
  @apply border border-gray-200;
}
.fw-select .select select {
  @apply border border-gray-200;
}
.fw-select .select:not(.is-multiple) {
  height: 2.5rem;
}
.fw-select .select select {
  height: 2.5rem;
  padding-bottom: 0px;
  padding-top: 0px;
}

.fw-select .select select:focus {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7);
  outline-offset: 2px;
  box-shadow: none;
}

.multiselect {
  @apply rounded shadow border border-gray-200;
}

.multiselect .multiselect__select:before {
  color: #999;
  border-color: #999 transparent transparent;
  border-radius: 2px;
}

.fw-form button {
  outline: none;
}

.fw-form .textarea:focus,
.fw-form .input:focus,
.fw-form .taginput .taginput-container.is-focusable:focus,
.fw-form .datepicker .dropdown .input:focus,
.fw-form .datepicker .dropdown-trigger .input[readonly]:focus,
.fw-form select:focus,
.fw-form button:focus:not(.multiplechoice_option) {
  border-color: transparent;
  outline: solid 2px rgba(3, 164, 121, 0.7) !important;
  outline-offset: 2px !important;
  box-shadow: none;
}

.fw-form .error .textarea,
.fw-form .error .input,
.fw-form .error .datepicker .dropdown .input,
.fw-form .error .datepicker .dropdown-trigger .input[readonly],
.fw-form .error select,
.fw-form .error .multiselect {
  border-color: #f15f1fff !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.input-r .input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dropdown-trigger .control.has-icons-left .icon {
  top: 1px;
  height: 2.5em;
  width: 2.5em;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dropdown-trigger .control.has-icons-left .input {
  padding-left: 2.5em;
}

.fw-form .datepicker .dropdown .input,
.fw-form .datepicker .dropdown-trigger .input[readonly] {
  background-color: #f5f5f5;
}

.fw-form .textarea,
.fw-form .input,
.fw-form .taginput .taginput-container.is-focusable {
  min-height: 35px;
  background-color: #f5f5f5;
}

.fw-form .datepicker .dropdown .input::placeholder,
.fw-form .datepicker .dropdown-trigger .input[readonly]::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form .textarea::placeholder,
.fw-form .input::placeholder,
.fw-form .taginput .taginput-container.is-focusable::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form .b-numberinput .control .button {
  height: 38px;
  width: 38px;
}

.fw-form input::placeholder {
  color: rgba(108, 105, 105, 0.8);
}

.fw-form input::-ms-input-placeholder {
  color: rgba(108, 105, 105, 0.8);
}
.fw-form .select:not(.is-multiple) {
  height: 2.75rem;
}
.fw-form .select select {
  height: 2.75rem;
}
.fw-form .datepicker-header .pagination-previous {
  width: 3rem;
  height: 2.75rem;
}
.fw-form .datepicker-header .pagination-next {
  width: 3rem;
  height: 2.75rem;
}
.fw-form .bg-saved {
  background-color: rgba(47, 199, 149, 0.25);
}

.fw-form.text15x .text-2xl {
  @apply text-3xl;
}
.fw-form.text15x .text-lg {
  @apply text-xl;
}
.fw-form.text15x .text-sm {
  @apply text-base;
}
.fw-form.text20x .text-2xl {
  @apply text-4xl;
}
.fw-form.text20x .text-lg {
  @apply text-2xl;
}
.fw-form.text20x .text-sm {
  @apply text-lg;
}
</style>

<i18n>
  {
    "en": {
      "hasFormErrors": "There are errors in the form. Please check the fields in red.",
      "deleteFileConfirm": "Are you sure you want to delete this file?",
      "writeText": "Write here",
      "noSectionDocuments": "No documents in this section yet.",
      "id.type.label" : "Document type",
      "id.number": "Document number",
      "id.validDate.label": "Expire date",
      "phoneCountry": "Country",
      "phone": "Phone number",
      "id.type.placeholder": "Type of document",
      "id.type.cc": "Portuguese Citizen Card",
      "id.type.passport": "Passport",
      "errorLoadingAnswers": "Error loading answers",
      "vat": "VAT Number",
      "date_placeholder": "Select a date",
      "datetime_placeholder": "Select a date and time",
      "address": {
        "placeholder": "Enter your address",
        "street": "Street",
        "number": "Number",
        "state": "State",
        "locality": "Locality",
        "postal_code": "Postal code",
        "country": "Country"
      }
    },
    "pt": {
      "hasFormErrors": "Existem erros no formulário. Por favor verifique os campos a vermelho.",
      "deleteFileConfirm": "Tem certeza que deseja apagar este ficheiro?",
      "writeText": "Escreva aqui",
      "noSectionDocuments": "Nenhum documento nesta secção ainda.",
      "id.type.label" : "Tipo de documento",
      "id.number": "Número do documento",
      "id.validDate.label": "Data de validade",
      "phoneCountry": "País",
      "phone": "Número de telefone",
      "id.type.placeholder": "Tipo de documento",
      "id.type.cc": "Cartão de Cidadão Português",
      "id.type.passport": "Passaporte",
      "errorLoadingAnswers": "Erro ao carregar respostas",
      "vat": "Número de Contribuinte",
      "date_placeholder": "Selecione uma data",
      "datetime_placeholder": "Selecione uma data e hora",
      "address": {
        "placeholder": "Insira o seu endereço",
        "street": "Rua",
        "number": "Número",
        "state": "Estado",
        "locality": "Localidade",
        "postal_code": "Código Postal",
        "country": "País"
      }
    }
  }
  </i18n>
